<template>
  <metric-skeleton-loader v-if="loading" />
  <v-row v-else-if="loadReportContent" class="d-flex ">
    <div class="flex-grow-1">
      <v-col cols="12" v-if="!dialogOpenDetail">
        <wide-trait-score-card
          :title="personalityTraitsContent.factors.open_mindedness.title"
          :sub-title="personalityTraitsContent.factors.open_mindedness.subTitle"
          :result="Math.round(personalityTraitsContent.factors.open_mindedness.score)"
          color="green"/>
        <v-divider/>
        <wide-trait-score-card
          :title="personalityTraitsContent.factors.conscientiousness.title"
          :sub-title="personalityTraitsContent.factors.conscientiousness.subTitle"
          :result="Math.round(personalityTraitsContent.factors.conscientiousness.score)"
          color="orange"/>
        <v-divider/>
        <wide-trait-score-card
          :title="personalityTraitsContent.factors.extraversion.title"
          :sub-title="personalityTraitsContent.factors.extraversion.subTitle"
          :result="Math.round(personalityTraitsContent.factors.extraversion.score)"
          color="pink"/>
        <v-divider/>
        <wide-trait-score-card
          :title="personalityTraitsContent.factors.agreeableness.title"
          :sub-title="personalityTraitsContent.factors.agreeableness.subTitle"
          :result="Math.round(personalityTraitsContent.factors.agreeableness.score)"
          color="blue"/>
        <v-divider/>
        <wide-trait-score-card
          :title="personalityTraitsContent.factors.emotional_stability.title"
          :sub-title="personalityTraitsContent.factors.emotional_stability.subTitle"
          :result="Math.round(personalityTraitsContent.factors.emotional_stability.score)"
          color="green"/>
      </v-col>
    </div>

    <v-dialog cols="12" v-model="dialogOpenDetail" transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark @click="closeDetailedDialog">
            <v-icon>fa fa-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("assessments.big_5.title") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          Full breakdown coming soon.
        </v-card-text>
      </v-card>

    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import {computed, PropType, ref, watch} from "vue";
import {Feature, Big5Result, isYapiError, YapiError} from "@YenzaCT/sdk";

import WideTraitScoreCard from "@/pages/UserDetailPage/components/common/WideTraitScoreCard.vue";
import {AxiosResponse} from "axios";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import MetricSkeletonLoader
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/MetricSkeletonLoader.vue";

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  score: {
    type: Object as PropType<Big5Result>,
    required: true
  },
  dialogOpen: {
    type: Boolean,
    default: false,
    required: true
  },
});

const store = useGlobalStore();

const dialogOpenDetail = ref(props.dialogOpen);

watch(() => props.dialogOpen, (newVal) => {
  dialogOpenDetail.value = newVal;
});

function closeDetailedDialog() {
  dialogOpenDetail.value = false;
}

const loadReportContent = computed(() => {
  if (dataLoaded.value) {
    return personalityTraitsContent.value;
  }
  return null;
});
const dataLoaded = ref(false);
const loading = ref(false);
const personalityTraitsContent = ref();

async function fetchPersonalityTraitsReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse = await yapi.admin.user.getAssessmentReport(props.userId, Feature.PersonalityTraitsReport);
    personalityTraitsContent.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchPersonalityTraitsReportContent();
</script>

<style scoped>
.btn-bg {
  background-color: #F1F5F9;
  border-color: #CBD5E1;
}
</style>
