<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-people-group
    </template>
    <template #header>
      {{ cohort?.title }}
    </template>

    <template #breadcrumbs>
      <v-breadcrumbs
        density="compact"
        class="pl-0 text-caption"
      >
        <v-breadcrumbs-item :to="{ name: 'cohorts' }" class="text-decoration-underline" color="secondary">
          {{ $t('components.CohortDetailPage.cohorts') }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider/>
        <v-breadcrumbs-item class="font-weight-bold" color="secondary">
          {{ cohort?.title }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>

    <template #context-menu>
      <v-menu
        bottom
        transition="scale-transition"
        v-if="store.user?.app.role !== Role.Manager"
      >
        <template v-slot:activator="{ props }">
          <v-btn-primary
            v-bind="props"
            prepend-icon="fa fa-bars"
          >
            {{ $t('buttons.menu')}}
          </v-btn-primary>
        </template>
        <v-list min-width="250px">
          <v-list-item
            :disabled="!isAdminOrRoot"
            @click="showResetAssessmentsDialog = true"
          >
            {{ $t("components.CohortDetailPage.menu.reset_assessments") }}
          </v-list-item>
          <v-list-item
            v-if="isAdminOrRoot"
            :disabled="!isAdminOrRoot && !store.hasAbility(Entity.Cohort, Action.delete)"
            @click="showDeleteCohort = true"
          >
            <span class="text-red">{{ $t("buttons.delete") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-container v-if="cohort" class="py-4">
      <v-row >
        <v-col cols="12">
          <h2>{{ $t("components.CohortDetailPage.title") }}</h2>
        </v-col>
        <v-col cols="12">
          <cohort-details :cohort="cohort" />
        </v-col>
        <v-col cols="12" v-if="isAdminOrRoot">
          <managers-table @revoked="fetchCohort" v-if="isAdminOrRoot" :managers="managers" :entity-type="Entity.Cohort"/>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showResetAssessmentsDialog"  max-width="500">
      <reset-assessment-dialog
        :id="cohort?._id ?? ''"
        type="cohort"
        @close="onCloseResetAssessmentDialog"
      />
    </v-dialog>

    <v-dialog v-model="showDeleteCohort" max-width="500">
      <delete-cohort
        @close="showDeleteCohort = false"
        :confirm-string="cohort?.title ?? ''"
        :message="$t('components.CohortDetailPage.delete_message')"
      />
    </v-dialog>
  </authenticated-layout>
</template>

<script setup lang="ts">
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import {onMounted, ref, watch} from "vue";
import {Action, Cohort, Entity, isYapiError, Role, User, YapiError} from "@YenzaCT/sdk";
import {useRoute} from "vue-router";
import yapi from "@/lib/yapi";
import CohortDetails from "@/pages/CohortDetailPage/components/CohortDetails.vue";
import DeleteCohort from "@/pages/CohortDetailPage/components/DeleteCohort.vue";
import {useGlobalStore} from "@/store";
import ManagersTable from "@/components/ManagersTable.vue";
import ResetAssessmentDialog from "@/components/ResetAssessmentCard.vue";
const store = useGlobalStore();
const cohort = ref<Cohort>();
const managers = ref<User[]>([]);
const route = useRoute();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const showDeleteCohort = ref(false);
const showResetAssessmentsDialog = ref(false);

async function fetchCohort() {
  try {
    store.networkBusy = true;
    const cohortId = route.params.id as string;
    cohort.value = (await yapi.admin.cohort.get(cohortId)).data;

    if (isAdminOrRoot.value)
      managers.value = (await yapi.admin.managers.list(Entity.Cohort, cohortId)).data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

watch(() => route.params.id, async (newId, oldId) => {
  if (newId !== oldId) {
    await fetchCohort();
  }
});

const onCloseResetAssessmentDialog = async () => {
  showResetAssessmentsDialog.value = false;
};

onMounted(async () => {
  await fetchCohort();
});

</script>
