<template>
  <holland-code-skeleton-loader v-if="loading"/>
  <v-row v-else-if="!dialogOpenDetail && assessmentComplete" class="d-flex">
    <div class="flex-grow-1">
      <v-col cols="12">
        <v-row >
          <v-col cols="12" v-for="trait in ['primary', 'secondary']" :key="trait">
            <trait-score-card
              :title="hollandCodeReportContent.worker_types[trait].title"
              :score="hollandCodeReportContent.worker_types[trait].score"
              :s-v-g-icon="traitProperties[hollandCodeReportContent.worker_types[trait].code!].icon"
              :colour="traitProperties[hollandCodeReportContent.worker_types[trait].code].colour"
              :data-pw="`${trait}TraitScoreCard`"
            />
          </v-col>
        </v-row>
      </v-col>
    </div>
  </v-row>
  <v-dialog v-model="dialogOpenDetail" transition="dialog-bottom-transition" >
    <v-card v-if="score && assessmentComplete">
      <v-toolbar dark color="primary">
        <v-btn dark @click="closeDetailedDialog">
          <v-icon>fa fa-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("assessments.holland_code.title") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <worker-type-report  :componentData.prop="hollandCodeReportContent" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {builder, creator, influencer, organiser, thinker, helper} from "@/assets/reportSvgs";
import {PropType, computed, ref, watch} from "vue";
import {WorkerTypeReport} from "@YenzaCT/web-components";
import {
  HollandCodeResult,
  Feature,
  isYapiError,
  YapiError,
} from "@YenzaCT/sdk";
import TraitScoreCard from "@/pages/UserDetailPage/components/common/TraitScoreCard.vue";
import yapi from "@/lib/yapi";
import {AxiosResponse} from "axios";
import {useGlobalStore} from "@/store";
import HollandCodeSkeletonLoader
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/HollandCodeReport/HollandCodeSkeletonLoader.vue";

const store = useGlobalStore();

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  score: {
    type: Object as PropType<HollandCodeResult>,
    required: true
  },
  dialogOpen: {
    type: Boolean,
    default: false,
    required: true
  },
});

const dataLoaded = ref(false);
const loading = ref(false);
const hollandCodeReportContent = ref();

const dialogOpenDetail = ref(props.dialogOpen);
watch(() => props.dialogOpen, (newVal) => {
  dialogOpenDetail.value = newVal;
});
function closeDetailedDialog() {
  dialogOpenDetail.value = false;
}

const assessmentComplete = computed(() => {
  if (props.score) {
    type ScoreTypeKey = keyof typeof props.score.types;
    const requiredKeys: ScoreTypeKey[] = ["R", "I", "A", "S", "E", "C"];

    // Check if all required keys exist in props.score.types
    const allKeysExist = requiredKeys.every((key) => key in props.score.types);
    if (allKeysExist) {
      return requiredKeys.every((type) => {
        const scoreType = type as ScoreTypeKey;
        const scoreValue = props.score.types[scoreType]?.score;
        return scoreValue !== undefined && scoreValue !== null;
      });
    }
  }
  return false;
});

async function fetchHollandCodeReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse = await yapi.admin.user.getAssessmentReport(props.userId, Feature.WorkerTypeReport);
    hollandCodeReportContent.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchHollandCodeReportContent();

interface TraitProperties {
  [index: string]: TraitProperty;

  R: TraitProperty;
  I: TraitProperty;
  A: TraitProperty;
  S: TraitProperty;
  E: TraitProperty;
  C: TraitProperty;
}

interface TraitProperty {
  slug: string;
  icon: string;
  colour: string;
}

const traitProperties: TraitProperties = {
  R: {
    slug: "builder",
    icon: builder(50),
    colour: "#fc631d"
  },
  I: {
    slug: "thinker",
    icon: thinker(50),
    colour: "#0487ff"
  },
  A: {
    slug: "creator",
    icon: creator(50),
    colour: "#1eac30"
  },
  S: {
    slug: "helper",
    icon: helper(50),
    colour: "#ff5d5d"
  },
  E: {
    slug: "influencer",
    icon: influencer(50),
    colour: "#514ee4"
  },
  C: {
    slug: "organiser",
    icon: organiser(50),
    colour: "#ff3520"
  },
};

</script>

<style scoped>
.btn-bg {
  background-color: #F1F5F9;
  border-color: #CBD5E1;
}
</style>
