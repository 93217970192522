<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="false"
    :is-report-active="isReportActive"
    :allow-export="reportData.length > 0"
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    :disable-run-report-button="false"
    @click-run-report="runReport"
    @click-export-report="exportReportData"
    @change-cohort="onCohortChange"
    @clickReset="resetReport"
  />
  <user-personality-traits-data-table
    v-if="reportData.length > 0"
    :loading-data="loadingData"
    :report-data="reportData"
  />
  <ShowNoResults
    v-else-if="reportData.length === 0 && showReport && !loadingData"
  />
</template>

<script setup lang="ts">
import {ref} from "vue";
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import UserPersonalityTraitsDataTable from "@/pages/ReportsDetailPage/components/UserPersonalityTraitsDataTable.vue";
import {IPersonalityTraitsReportData} from "@/pages/ReportsDetailPage/reportTypes";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import ShowNoResults from "@/pages/ReportsDetailPage/components/NoResultsFoundCard.vue";

const store = useGlobalStore();
const showReport = ref(false);
const reportData = ref<IPersonalityTraitsReportData[]>([]);

const {
  timeFilter,
  tenantId,
  cohortId,
  institutionIds,
  isReportActive,
  loadingData,
  onTenantChange,
  onCohortChange,
  onInstitutionSelectionChange,
  exportData,
} = useInsights();

/**
 * Fetches the Users Big 5 Types report data from the API.
 */
async function runReport() {
  try {
    loadingData.value = true;
    store.networkBusy = true;
    const res = await yapi.admin.reports.post("users-big-5-types", {
      institutionIds: institutionIds.value,
      tenantId: tenantId.value,
      cohortId: cohortId.value,
      time: timeFilter.value
    });

    reportData.value = res.data as unknown as IPersonalityTraitsReportData[];
    isReportActive.value = true;
    showReport.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loadingData.value = false;
    store.networkBusy = false;
  }
}

function resetReport() {
  reportData.value = [];
  showReport.value = false;
  isReportActive.value = false;
  institutionIds.value = [];
  tenantId.value = undefined;
  cohortId.value = undefined;
}

/**
 * Exports the Users Big 5 Types report data.
 */
function exportReportData(format: string) {
  exportData("users-big-5-types", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    time: timeFilter.value,
    breakdownByCohort: true,
    breakDownByInstitution: true,
    format
  });
}
</script>
