<template>
  <v-card
    :title="$t('components.DeleteVouchers.title')"
    data-pw="deleteVouchersCard"
  >
    <v-card-text>
      <danger-confirmation-field
        v-model="confirmDanger"
        :message="$t('components.DeleteVouchers.warning_message', {quantity: multipleVoucherIds!.length})"
        confirm-string=""
        :action-label="$t('common.action_label')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        data-pw="cancelButton"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        data-pw="confirmDeleteButton"
        @click="deleteVouchers"
        v-bind:disabled="!confirmDanger"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {ref} from "vue";
import yapi from "@/lib/yapi";
import router from "@/router";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import DangerConfirmationField from "@/components/DangerConfirmationField.vue";

const props = defineProps({
  multipleVoucherIds: {
    type: Array,
    required: false
  }
});

const emits = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const store = useGlobalStore();

const confirmDanger = ref<boolean>(false);

const deleteVouchers = async () => {
  try {
    await yapi.admin.accessCode.removeMultiple(
      props.multipleVoucherIds as string[]
    );
    emits("close");
    await router.push({name: "vouchers"});
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};
</script>

<style scoped>

</style>
