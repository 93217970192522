<template #context-menu>
  <v-form>
    <v-card  :title="$t('components.ExportUserDialog.title')"
             class="ma-2 pa-2">
      <v-card-text>
        <div class="text-left text-body-2">
          <div>
            <b>{{ $t('components.ExportUserDialog.activeFilters') }}</b>
            <template v-for="column in cols">
              <v-chip
                :key="`${column.modelKey}_export_filter`"
                class="ml-2"
                size="small"
                v-if="column.filter && column.filterText">{{ column.title }}: <b> {{ column.filterText }}</b></v-chip>
            </template>
            <v-chip
              v-if="!Object.values(cols).some(column => column.filter && column.filterText)"
              class="ml-2">
              {{t('common.none')}}
            </v-chip>
          </div>

          <div>
            <b>{{ $t('components.ExportAccessCodes.format') }}: </b>
            <v-chip   class="ml-2" size="small">
              {{ $t(`common.${format}`) }}
            </v-chip>

          </div>
        </div>

        <div class="my-6">
          <v-checkbox
            v-model="selectAll"
            :label="$t('common.select_all')"
            hide-details
            density="compact"
            @update:model-value="toggleAllSelections"
          ></v-checkbox>
        </div>
        <div>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ $t('components.ExportUserDialog.dialog_items.personal_information.title') }}
                  <div class="d-flex flex-wrap gap-1 mt-1">
                    <template v-for="option in exportOptions.personalInformation.options">
                      <v-chip
                        v-if="selected[option.key]"
                        :key="option.key"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ option.title }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="sectionSelections.personalInformation"
                  :label="$t('common.select_all')"
                  hide-details
                  density="compact"
                  class="mb-2"
                  @update:model-value="value => toggleSectionSelection('personalInformation', value)"
                ></v-checkbox>
                <v-checkbox
                  v-for="option in exportOptions.personalInformation.options"
                  :key="option.key"
                  v-model="selected[option.key]"
                  :label="option.title"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ $t('components.ExportUserDialog.dialog_items.tenant_data.title') }}
                  <div class="d-flex flex-wrap gap-1 mt-1">
                    <template v-for="option in exportOptions.tenantData.options">
                      <v-chip
                        v-if="selected[option.key]"
                        :key="option.key"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ option.title }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="sectionSelections.tenantData"
                  :label="$t('common.select_all')"
                  hide-details
                  density="compact"
                  class="mb-2"
                  @update:model-value="value => toggleSectionSelection('tenantData', value)"
                ></v-checkbox>
                <v-checkbox
                  v-for="option in exportOptions.tenantData.options"
                  :key="option.key"
                  v-model="selected[option.key]"
                  :label="option.title"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ exportOptions.accountSetup.title }}
                  <div class="d-flex flex-wrap gap-1 mt-1">
                    <template v-for="option in exportOptions.accountSetup.options">
                      <v-chip
                        v-if="selected[option.key]"
                        :key="option.key"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ option.title }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="sectionSelections.accountSetup"
                  :label="$t('common.select_all')"
                  hide-details
                  density="compact"
                  class="mb-2"
                  @update:model-value="value => toggleSectionSelection('accountSetup', value)"
                ></v-checkbox>
                <v-checkbox
                  v-for="option in exportOptions.accountSetup.options"
                  :key="option.key"
                  v-model="selected[option.key]"
                  :label="option.title"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ exportOptions.choices.title }}
                  <div class="d-flex flex-wrap gap-1 mt-1">
                    <template v-for="option in exportOptions.choices.options">
                      <v-chip
                        v-if="selected[option.key]"
                        :key="option.key"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ option.title }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="sectionSelections.choices"
                  :label="$t('common.select_all')"
                  hide-details
                  density="compact"
                  class="mb-2"
                  @update:model-value="value => toggleSectionSelection('choices', value)"
                ></v-checkbox>
                <v-checkbox
                  v-for="option in exportOptions.choices.options"
                  :key="option.key"
                  v-model="selected[option.key]"
                  :label="option.title"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ exportOptions.consents.title }}
                  <div class="d-flex flex-wrap gap-1 mt-1 ">
                    <template v-for="option in exportOptions.consents.options">
                      <v-chip
                        v-if="selected[option.key]"
                        :key="option.key"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ option.title }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="sectionSelections.accountSetup"
                  :label="$t('common.select_all')"
                  hide-details
                  density="compact"
                  class="mb-2"
                  @update:model-value="value => toggleSectionSelection('accountSetup', value)"
                ></v-checkbox>
                <v-checkbox
                  v-for="option in exportOptions.consents.options"
                  :key="option.key"
                  v-model="selected[option.key]"
                  :label="option.title"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ $t("components.ExportUserDialog.dialog_items.reports.title") }}
                  <div class="d-flex flex-wrap gap-1 mt-1">
                    <template v-for="report in exportOptions.reports">
                      <v-chip
                        v-if="selected[report.slug] === 'all' || selected[report.slug] === 'totals'"
                        :key="report.slug"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ report.title }}: {{ selected[report.slug] === 'all' ? $t('components.ExportUserDialog.dialog_items.reports.all_data')
                          : $t('components.ExportUserDialog.dialog_items.reports.totals') }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-radio-group
                  v-model="allAssessmentsSelection"
                  :inline="true"
                  hide-details
                  class="mb-2"
                >
                  <v-radio
                    :label="$t('components.ExportUserDialog.dialog_items.reports.all_data')"
                    value="all"
                    hide-details
                  ></v-radio>
                  <v-radio
                    :label="$t('components.ExportUserDialog.dialog_items.reports.totals')"
                    value="totals"
                    hide-details
                  ></v-radio>
                  <v-radio
                    :label="$t('components.ExportUserDialog.dialog_items.reports.none')"
                    value="false"
                    hide-details
                  ></v-radio>
                </v-radio-group>
                <v-list density="compact">
                  <v-list-item
                    v-for="report in exportOptions.reports"
                    :key="report.slug"
                    density="compact"
                  >
                    <v-list-item-title>
                      {{ report.title }}
                    </v-list-item-title>
                    <v-list-item-action class="pl-4">
                      <v-radio-group
                        v-model="selected[report.slug]"
                        :inline="true"
                        hide-details
                      >
                        <v-radio
                          :label="$t('components.ExportUserDialog.dialog_items.reports.all_data')"
                          value="all"
                          hide-details
                        ></v-radio>
                        <v-radio
                          :label="$t('components.ExportUserDialog.dialog_items.reports.totals')"
                          value="totals"
                          hide-details
                        ></v-radio>
                        <v-radio
                          :label="$t('components.ExportUserDialog.dialog_items.reports.none')"
                          value="false"
                          hide-details
                        ></v-radio>
                      </v-radio-group>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel v-if="isAdminOrRoot">
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ exportOptions.research.title }}
                  <div class="d-flex flex-wrap gap-1 mt-1">
                    <template v-for="option in exportOptions.research.options">
                      <v-chip
                        v-if="selected[option.key]"
                        :key="option.key"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ option.title }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="sectionSelections.reports"
                  :label="$t('common.select_all')"
                  hide-details
                  density="compact"
                  class="mb-2"
                  @update:model-value="value => toggleSectionSelection('reports', value)"
                ></v-checkbox>
                <v-checkbox
                  v-for="option in exportOptions.research.options"
                  :key="option.key"
                  v-model="selected[option.key]"
                  :label="option.title"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel v-if="isAdminOrRoot">
              <v-expansion-panel-title>
                <div class="d-flex flex-column">
                  {{ $t('components.ExportUserDialog.dialog_items.researchAssessments.title') }}
                  <div class="d-flex flex-wrap gap-1 mt-1">
                    <template v-for="option in exportOptions.researchAssessments">
                      <v-chip
                        v-if="selected[option.key]"
                        :key="option.key"
                        size="x-small"
                        color="primary"
                        class="mr-1 my-1"
                      >
                        {{ option.title }}
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="sectionSelections.researchAssessments"
                  :label="$t('common.select_all')"
                  hide-details
                  density="compact"
                  class="mb-2"
                  @update:model-value="value => toggleSectionSelection('researchAssessments', value)"
                ></v-checkbox>
                <v-checkbox
                  v-for="option in exportOptions.researchAssessments"
                  :key="option.key"
                  v-model="selected[option.key]"
                  :label="option.title"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn-secondary
          @click="$emit('close')"
        >
          {{ $t('buttons.cancel') }}
        </v-btn-secondary>
        <v-btn-primary
          data-pw="confirmExportButton"
          @click="downloadExcel"
        >
          {{ $t('buttons.confirm') }}
        </v-btn-primary>
      </v-card-actions>
    </v-card>

  </v-form>
</template>

<script lang="ts" setup>
import {PropType, ref, watch} from "vue";
import {Role, UserExportSelectors,} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import {ColumnsConfig} from "@/lib/pagination";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
const {t} = useI18n();
const selected = ref<UserExportSelectors>({});
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const selectAll = ref(false);
const sectionSelections = ref({
  personalInformation: false,
  tenantData: false,
  accountSetup: false,
  choices: false,
  consents: false,
  reports: false,
  research: false,
  researchAssessments: false,
});
const allAssessmentsSelection = ref("false");

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  cols: {
    type: Object as PropType<ColumnsConfig>,
    required: true
  },
  format: {
    type: String,
    required: true
  }
});

const emit = defineEmits<{
  (e: "download", selected: UserExportSelectors): void,
  (e: "close"): void;
}>();

selected.value = {
  uuid: false,
  email: false,
  phone: false,
  profileContactNumber: false,
  firstName: false,
  lastName: false,
  tenant: false,
  institution: false,
  cohort: false,
  birthDate: false,
  nationality: false,
  idNumber: false,
  accountType: false,
  accessCode: false,
  termsConsent: false,
  consentedAt: false,
  researchProfile: false,
  researchConsent: false,
  marketingConsent: false,
  internalMarketingSharingConsent: false,
  competitionConsentedAt: false,
  competitionConsented: false,
  parentalConsent: false,
  createdAt: false,
  role: false,
  holland_code: false,
  entrepreneur: false,
  big_5: false,
  understanding_emotion: false,
  perceiving_emotion: false,
  managing_emotion: false,
  rock_paper_scissors: false,
  learning_styles: false,
  maze: false,
  ipip: false,
  interests: false,
  subjectChoices: false,
  exportExcelReport: false
};

const exportOptions = {
  tenantData: {
    title: t("components.ExportUserDialog.dialog_items.personal_information.title"),
    slug: "personalInformation",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.tenant_data.tenant"),
        key: "tenant"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.tenant_data.institution"),
        key: "institution"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.tenant_data.cohort"),
        key: "cohort"
      }
    ]
  },
  personalInformation: {
    title: t("components.ExportUserDialog.dialog_items.personal_information.title"),
    slug: "personalInformation",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.email"),
        key: "email"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.cell_phone_authentication"),
        key: "phone"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.cell_phone_profile"),
        key: "profileContactNumber"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.first_name"),
        key: "firstName"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.last_name"),
        key: "lastName"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.date_of_birth"),
        key: "birthDate"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.id_number"),
        key: "idNumber"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.nationality"),
        key: "nationality"
      },
    ]
  },
  accountSetup: {
    title: "Account Setup",
    slug: "accountSetup",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.account_type"),
        key: "accountType"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.voucher_code"),
        key: "accessCode"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.role"),
        key: "role"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.time_stamps"),
        key: "createdAt"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.last_login"),
        key: "lastLogin"
      },
    ]
  },
  choices: {
    title: t("components.ExportUserDialog.dialog_items.choices.title"),
    slug: "choices",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.choices.subject_choices"),
        key: "subjectChoices"
      }
    ]
  },
  research: {
    title: t("components.ExportUserDialog.dialog_items.research.title"),
    slug: "research",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.research.research_profile"),
        key: "researchProfile"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.research.research_consent"),
        key: "researchConsent"
      },
    ]
  },
  consents: {
    title: t("components.ExportUserDialog.dialog_items.consents.title"),
    slug: "consents",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.consents.terms_consent"),
        key: "termsConsent"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.competition_consent"),
        key: "competitionConsented"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.parental_consent"),
        key: "parentalConsent"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.marketing_consent"),
        key: "marketingConsent"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.internal_marketing_consent"),
        key: "internalMarketingSharingConsent"
      },
    ]
  },
  reports: {
    holland_code: {
      title: t("components.ExportUserDialog.dialog_items.reports.worker_type"),
      slug: "holland_code",
    },
    entrepreneur: {
      title: t("components.ExportUserDialog.dialog_items.reports.entrepreneur"),
      slug: "entrepreneur",
    },
    big_5: {
      title: t("components.ExportUserDialog.dialog_items.reports.big_5"),
      slug: "big_5",
    },
    learning_styles: {
      title: t("components.ExportUserDialog.dialog_items.reports.learning_styles"),
      slug: "learning_styles",
    },
    perceiving_emotion: {
      title: t("components.ExportUserDialog.dialog_items.reports.perceiving_emotion"),
      slug: "perceiving_emotion",
    },
    managing_emotion: {
      title: t("components.ExportUserDialog.dialog_items.reports.managing_emotion"),
      slug: "managing_emotion",
    },
    understanding_emotion: {
      title: t("components.ExportUserDialog.dialog_items.reports.understanding_emotion"),
      slug: "understanding_emotion",
    },
    rock_paper_scissors: {
      title: t("components.ExportUserDialog.dialog_items.reports.rock_paper_scissors"),
      slug: "rock_paper_scissors",
    },
    maze: {
      title: t("components.ExportUserDialog.dialog_items.reports.maze"),
      slug: "maze",
    },
    value_scales: {
      title: t("components.ExportUserDialog.dialog_items.reports.value_scales"),
      slug: "value_scales",
    }
  },
  researchAssessments: {
    interests: {
      title: t("components.ExportUserDialog.dialog_items.researchAssessments.interests"),
      key: "interests",
    },
    ipip: {
      title: t("components.ExportUserDialog.dialog_items.researchAssessments.ipip"),
      key: "ipip",
    },
  }
};

async function downloadExcel() {
  emit("download", selected.value);
}

function toggleSectionSelection(section: keyof typeof exportOptions, value: boolean | null) {
  if (value === null) return;

  if (section === "reports") {
    Object.values(exportOptions.reports).forEach((report) => {
      selected.value[report.slug] = value ? "all" : "false";
    });
  } else if (section === "researchAssessments") {
    Object.values(exportOptions.researchAssessments).forEach((assessment) => {
      selected.value[assessment.key] = value;
    });
  } else {
    const sectionData = exportOptions[section as keyof typeof exportOptions];
    if ("options" in sectionData) {
      sectionData.options.forEach((option) => {
        selected.value[option.key] = value;
      });
    }
  }
}

watch(allAssessmentsSelection, (newValue) => {
  Object.values(exportOptions.reports).forEach((report) => {
    selected.value[report.slug] = newValue;
  });
});

(Object.keys(exportOptions) as Array<keyof typeof exportOptions>).forEach((section) => {
  watch(
    () => selected.value,
    (newVal) => {
      if (section === "reports") {
        sectionSelections.value.reports = Object.values(exportOptions.reports)
          .every((report) => newVal[report.slug] === "all");
      } else if (section === "researchAssessments") {
        sectionSelections.value.researchAssessments = Object.values(exportOptions.researchAssessments)
          .every((assessment) => newVal[assessment.key] === true);
      } else {
        const sectionData = exportOptions[section];
        if ("options" in sectionData) {
          sectionSelections.value[section] = sectionData.options
            .every((option) => newVal[option.key] === true);
        }
      }
    },
    { deep: true }
  );
});

watch(selected, (newVal) => {
  const allValues = Object.values(newVal);
  selectAll.value = allValues.every((value) =>
    value === true || value === "all");
}, { deep: true });

function toggleAllSelections(value: boolean | null) {
  if (value === null) return;
  Object.keys(sectionSelections.value).forEach((section) => {
    sectionSelections.value[section as keyof typeof sectionSelections.value] = value;
  });
  Object.keys(selected.value).forEach((key) => {
    if (key in exportOptions.reports) {
      selected.value[key] = value ? "all" : "false";
    } else {
      selected.value[key] = value;
    }
  });
  allAssessmentsSelection.value = value ? "all" : "false";
}
</script>
