<script setup lang="ts">
import {PropType, ref, Ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import UserSubjectChoicesDataTable from "@/pages/ReportsDetailPage/components/UserSubjectChoicesDataTable.vue";
import {downloadCSV, downloadExcel} from "@/lib/fileDownload";
import DataExportDownloadMenu from "@/components/DataExportDownloadMenu.vue";

const store = useGlobalStore();
const loading = ref(false);
const tableData: Ref<Record<string, unknown>[]> = ref([]);

const props = defineProps({
  tenantId: {
    type: String,
    required: false,
    default: null
  },
  cohortId: {
    type: String,
    required: false,
    default: null
  },
  institutionIds: {
    type: Array as PropType<string[]>,
    required: false,
    default: null
  },
  timeFilter: {
    type: Object as PropType<{ dateFilterType: string, unit: string, value: string | string[] }[]>,
    required: true,
    default: null
  },
  curriculum: {
    type: String,
    required: true,
    default: null
  }
});

async function fetchReportData() {
  try {
    store.networkBusy = true;
    loading.value = true;
    const res = await yapi.admin.reports.post("users-subject-choices", {
      institutionIds: props.institutionIds,
      cohortId: props.cohortId,
      tenantId: props.tenantId,
      curriculum: props.curriculum,
      time: props.timeFilter
    });
    if (res.data)
      tableData.value = res.data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
    store.networkBusy = false;
  }
}

async function exportData(format: string) {
  try {
    loading.value = true;
    store.networkBusy = true;
    const res = await yapi.admin.reports.export("users-subject-choices", {
      institutionIds: props.institutionIds,
      cohortId: props.cohortId,
      tenantId: props.tenantId,
      curriculum: props.curriculum,
      breakdownByCohort: true,
      breakdownByInstitution: true,
      time: props.timeFilter,
      format
    });

    if (res.data.format === "excel")
      downloadExcel(res.data.content.toString(), "users-subject-choices");
    else downloadCSV(res.data.content.toString(), "users-subject-choices");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
    store.networkBusy = false;
  }
}

fetchReportData();
</script>

<template>
  <v-card variant="elevated">
    <template #append>
      <data-export-download-menu :formats="['csv', 'excel']" @click="exportData"/>
    </template>
    <v-card-text>
      <user-subject-choices-data-table :loading-data="loading" :curriculum="curriculum" :report-data="tableData"/>
    </v-card-text>
  </v-card>
</template>
