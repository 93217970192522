<template>
  <v-card :title="$t('components.DeleteInstitution.title')">
    <v-card-text>
      <v-alert density="compact" v-if="error" type="error" class="mb-4"> {{ error }}</v-alert>
      <danger-confirmation-field
        v-model="confirmDanger"
        :message="message"
        :confirm-string="confirmString"
        :action-label="$t('common.action_label')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        @click="deleteInstitution"
        v-bind:disabled="!confirmDanger"
      >
        {{ $t("buttons.delete") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import DangerConfirmationField from "@/components/DangerConfirmationField.vue";

const {t} = useI18n();
const router = useRouter();

defineProps({
  confirmString: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  }
});

defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const store = useGlobalStore();
const route = useRoute();

const confirmDanger = ref<boolean>(false);
const institutionId = route.params.id as string;
const error = ref<string | undefined>("");
const deleteInstitution = async () => {
  try {
    await yapi.admin.institution.remove(institutionId);
    await router.push({name: "institutions"});
    store.showSnackbar(t("components.DeleteInstitution.success_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      error.value = yError.response?.data.message;
    } else {
      throw e;
    }
  }
};
</script>

