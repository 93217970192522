<template>
  <v-card flat color="grey-lighten-4" class="mb-6">
    <v-card-title>
      New Account Settings
    </v-card-title>
    <v-card-text>
      <configuration-section-row :show-divider="false">
        <template #name>
          Default Account Type
        </template>
        <template #comment>
          New signups will default to this account type
        </template>
        <v-select
          variant="outlined"
          density="compact"
          v-model="selectedDefaultAccountType"
          :label="$t('components.defaultAccountTypeSelector.label')"
          :items="accountTypeOptions"/>
      </configuration-section-row>

      <configuration-section-row>
        <template #name>
          Grant Terms Consent Automatically
        </template>
        <template #comment>
          If ticked terms are granted on account creation instead of requiring user to accept manually.
        </template>
        <labeled-switch
          v-model="defaultTermsConsent"
        />
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="secondary"
        @click="reset"
        :disabled="!changed"
      >
        {{ $t('buttons.reset') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="confirmVisible = true"
        :disabled="!valid || !changed"
      >
        {{ $t('buttons.apply') }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <confirm-dialog
    :title="$t('common.confirm_title')"
    :text="$t('common.confirm_text')"
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
  />
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {useGlobalStore} from "@/store";
import {AccountType, isYapiError, YapiError} from "@YenzaCT/sdk";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {isEqual} from "lodash";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/components/ConfigurationSectionRow.vue";
import {useI18n} from "vue-i18n";
import LabeledSwitch from "@/components/LabeledSwitch.vue";

const store = useGlobalStore();
const {t} = useI18n();

const confirmVisible = ref(false);

const defaultTermsConsent = ref(store.appConfiguration.defaultTermsConsent);
const selectedDefaultAccountType = ref<AccountType>(store.appConfiguration.defaultAccountType as AccountType);

const accountTypeOptions = ref(Object.values(AccountType)
  .map((slug) => ({
    title: t(`accountTypes.${slug}`),
    value: slug
  })));

const changed = computed(() => store.appConfiguration.defaultTermsConsent !== defaultTermsConsent.value ||
  !isEqual(selectedDefaultAccountType.value, store.appConfiguration.defaultAccountType));
const valid = computed(() => !!selectedDefaultAccountType.value.length);

function reset() {
  selectedDefaultAccountType.value = store.appConfiguration.defaultAccountType as AccountType;
}

async function save() {
  // First save available, as they are required for default
  await saveDefault();
  await saveTermsConsent();
  confirmVisible.value = false;
}

async function saveTermsConsent() {
  try {
    await store.saveAppConfiguration("defaultTermsConsent", defaultTermsConsent.value);
    store.showSnackbar("Default terms consent updated");
  } catch (e) {
    if (isYapiError(e)) {
      const error = e as YapiError;
      store.showSnackbar(error.message);
    } else {
      store.showSnackbar("An error occurred while saving the terms consent setting");
      confirmVisible.value = false;
      reset();
    }
  }
}

async function saveDefault() {
  try {
    await store.saveAppConfiguration("defaultAccountType", selectedDefaultAccountType.value);
    store.showSnackbar("Default account type updated");
  } catch (e) {
    if (isYapiError(e)) {
      const error = e as YapiError;
      store.showSnackbar(error.message);
    } else {
      store.showSnackbar("An error occurred while saving the account type");
      confirmVisible.value = false;
      reset();
    }
  }
}
</script>
