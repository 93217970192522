<template>
  <v-card :title="title">
    <v-card-text>
      <v-alert density="compact" v-if="error" type="error" class="mb-4"> {{ error }}</v-alert>
      <danger-confirmation-field
        v-model="confirmDanger"
        :message="message"
        :confirm-string="confirmString"
        :action-label="$t('common.action_label')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        @click="deleteTenant"
        v-bind:disabled="!confirmDanger"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {useRoute, useRouter} from "vue-router";
import DangerConfirmationField from "@/components/DangerConfirmationField.vue";
const router = useRouter();

defineProps({
  title: {
    type: String,
    required: true
  },
  confirmString: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  }
});

defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const store = useGlobalStore();
const route = useRoute();

const tenantId = route.params.id as string;
const error = ref<string | undefined>("");

const confirmDanger = ref<boolean>(false);

const deleteTenant = async () => {
  try {
    await yapi.admin.tenant.remove(tenantId);
    await router.push({name: "tenants"});
    store.showSnackbar("Tenant deleted successfully");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      error.value = yError.response?.data.message;
    } else {
      throw e;
    }
  }
};
</script>

