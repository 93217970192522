<template>
  <v-card :title="title">
    <v-card-text>
      <danger-confirmation-field
        v-model="confirmDanger"
        :message="$t('components.DisableEnableTenantDialog.warning', { action: disabled ? 'enable' : 'disable' })"
        :confirm-string="confirmString"
        :action-label="$t('components.DisableEnableTenantDialog.action_label')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        @click="disableEnableUser"
        :disabled="!confirmDanger"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import DangerConfirmationField from "@/components/DangerConfirmationField.vue";

const {t} = useI18n();
const store = useGlobalStore();

const route = useRoute();

const props = defineProps({
  disabled: {
    type: Boolean,
    required: true
  }
});

const confirmString = ref("");
const tenantId = route.params.id as string;
const confirmDanger = ref<boolean>(false);

const emits = defineEmits<{
  (e: "close"): void;
}
>();

const title = computed(
  () => t(props.disabled ? "components.DisableEnableTenantDialog.enable_tenant" : "components.DisableEnableTenantDialog.disable_tenant")
);

const disableEnableUser = async () => {
  try {
    store.networkBusy = true;
    const res = await (
      props.disabled
        ? yapi.admin.tenant.enable(tenantId)
        : yapi.admin.tenant.disable(tenantId)
    );
    emits("close");
    store.showSnackbar(t(res.data.disabled ? "components.DisableEnableTenantDialog.tenant_enabled" : "components.DisableEnableTenantDialog.tenant_disabled"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
};

</script>
