<template>
  <div class="bg-red-lighten-5 rounded border mt-1 pa-3">
    <div class="mb-2">
      <v-icon class="mr-2" color="red" size="small">fa-solid fa-exclamation-circle</v-icon>
      <span class="text-red font-weight-bold">
        {{ $t("components.ResetAssessmentDialog.danger_zone") }}
      </span>
      <span> | {{ actionLabel }}</span>
    </div>
    <span class="text-red font-weight-bold">
      {{ message + confirmString }}
    </span>
    <v-text-field
      v-model="verifyString"
      density="compact"
      variant="outlined"
      :hide-details="true"
      class="mb-2 mt-4"
      data-pw="confirmDeleteTextField"
    />
    <span>{{ $t('common.type_confirm_message') }}</span>
  </div>
</template>

<script lang="ts" setup>
import {defineProps, ref, watch, defineModel} from "vue";

defineProps({
  message: {
    type: String,
    required: true,
  },
  confirmString: {
    type: String,
    required: true,
  },
  actionLabel: {
    type: String,
    required: true
  },
});

const valid = defineModel<boolean>();
const verifyString = ref("");

watch(verifyString, () => {
  valid.value = verifyString.value === "CONFIRM";
});
</script>
