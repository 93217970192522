<template>
  <v-card>
    <v-card-text>
      <danger-confirmation-field
        v-model="confirmDanger"
        :message="$t('components.AccessCodeDetailPage.message_warning')"
        :confirm-string="confirmString"
        :action-label="$t('components.ResetAssessmentDialog.action_label')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        data-pw="cancelButton"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        data-pw="confirmDeleteButton"
        @click="deleteVoucher"
        v-bind:disabled="!confirmDanger"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {Ref, ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import DangerConfirmationField from "@/components/DangerConfirmationField.vue";

const {t} = useI18n();
const router = useRouter();

defineProps({
  confirmString: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  }
});

defineEmits<{
  (e: "close"): void;
}>();

const store = useGlobalStore();

const route = useRoute();

const voucherId: Ref<string> = ref("");
const confirmDanger = ref<boolean>(false);

voucherId.value = route.params.id as string;

const deleteVoucher = async () => {
  try {
    await yapi.admin.accessCode.remove(voucherId.value);
    await router.push({name: "vouchers"});
    store.showSnackbar(t("components.DeleteVoucher.deleted_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};
</script>
