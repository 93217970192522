<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card :title="$t('components.DeleteCouponDialog.title')">
      <v-card-text>
        <danger-confirmation-field
          v-model="confirmDanger"
          :message="$t('components.DeleteCouponDialog.warning_message')"
          :confirm-string="code!"
          :action-label="$t('common.action_label')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn-secondary
          @click="dialog = false"
        >
          {{ $t("buttons.cancel") }}
        </v-btn-secondary>
        <v-btn-delete
          @click="deleteConfirmed"
          v-bind:disabled="!confirmDanger"
        >
          {{ $t("buttons.delete") }}
        </v-btn-delete>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {PropType, ref, watch} from "vue";
import DangerConfirmationField from "@/components/DangerConfirmationField.vue";

const props = defineProps({
  modelValue: Boolean as PropType<boolean>,
  code: String,
});

const dialog = ref(props.modelValue);
const emit = defineEmits(["update:modelValue", "delete"]);
const confirmDanger = ref<boolean>(false);

watch(() => props.modelValue, (newValue) => {
  dialog.value = newValue;
});

watch(() => dialog.value, (newValue) => {
  emit("update:modelValue", newValue);
});

function deleteConfirmed() {
  dialog.value = false;
  emit("delete");
}
</script>

