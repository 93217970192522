<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between align-center">
      <h2>{{ $t("components.UserDetailPage.user_detail_section_heading.assessment_reports") }}</h2>
    </v-card-title>
    <v-divider class="mx-2"/>
    <v-card-text>
      <v-carousel
        v-model="currentCarouselItem"
        :show-arrows="false"
        hide-delimiter-background
        hide-delimiters
        delimiter-icon="mdi-minus"
        height="auto"
      >
        <v-carousel-item v-for="n in reports.length" v-bind:key="`carousel-${n}`">
          <v-row>
            <v-col cols="12" md="4" v-if="canShowReportCard(n,Feature.WorkerTypeReport)">
              <assessment-results-summary-card
                :complete="reportAssessmentsComplete[Feature.WorkerTypeReport]"
                :title="$t(`assessments.holland_code.title`)"
                append-icon="fas fa-compass"
                @view-report="handleViewReport('holland_code')"
              >
                <holland-code-report
                  v-if="reportAssessmentsComplete[Feature.WorkerTypeReport]"
                  :user-id="user._id!"
                  :score="user.assessments.holland_code!"
                  :dialog-open="showDetailedReports.holland_code"
                />
              </assessment-results-summary-card>
            </v-col>
            <v-col cols="12" md="4" v-if="canShowReportCard(n,Feature.PersonalityTraitsReport)">
              <assessment-results-summary-card
                :complete="reportAssessmentsComplete[Feature.PersonalityTraitsReport]"
                :title="$t(`assessments.big_5.title`)"
                append-icon="fas fa-street-view"
                @view-report="handleViewReport('big_5')"
              >
                <big5-report
                  v-if="reportAssessmentsComplete[Feature.PersonalityTraitsReport] && user.assessments.big_5"
                  :user-id="user._id!"
                  :score="user.assessments.big_5"
                  :dialog-open="showDetailedReports.big_5"
                />
              </assessment-results-summary-card>
            </v-col>
            <v-col cols="12" md="4" v-if="canShowReportCard(n,Feature.LearningStylesReport)">
              <assessment-results-summary-card
                :complete="reportAssessmentsComplete[Feature.LearningStylesReport]"
                :title="$t(`assessments.learning_styles.title`)"
                :append-icon="`fas fa-school`"
                @view-report="handleViewReport('learning_styles')"
              >
                <learning-styles-report
                  v-if="reportAssessmentsComplete[Feature.LearningStylesReport] && user.assessments.learning_styles"
                  :user-id="user._id!"
                  :score="user.assessments.learning_styles"
                  :dialog-open="showDetailedReports.learning_styles"
                />
              </assessment-results-summary-card>
            </v-col>
            <v-col cols="12" md="4" v-if="canShowReportCard(n,Feature.CognitiveAbilityReport)">
              <assessment-results-summary-card
                :complete="reportAssessmentsComplete[Feature.CognitiveAbilityReport]"
                :title="$t(`assessments.cognitive_ability.title`)"
                append-icon="fas fa-school"
                @view-report="handleViewReport('cognitive_ability')"
              >
                <cognitive-ability-report
                  v-if="reportAssessmentsComplete[Feature.CognitiveAbilityReport]"
                  :maze="user.assessments.maze!"
                  :rock-paper-scissors="user.assessments.rock_paper_scissors!"
                  :dialog-open="showDetailedReports.cognitive_ability"
                  :user-id="user._id!"
                />
              </assessment-results-summary-card>
            </v-col>
            <v-col cols="12" md="4" v-if="canShowReportCard(n,Feature.EmotionalIntelligenceReport)">
              <assessment-results-summary-card
                :complete="reportAssessmentsComplete[Feature.EmotionalIntelligenceReport]"
                :title="$t(`assessments.emotional_intelligence.title`)"
                append-icon="fas fa-school"
                @view-report="handleViewReport('perceiving_emotion')"
              >
                <emotional-intelligence-report
                  v-if="reportAssessmentsComplete[Feature.EmotionalIntelligenceReport]"
                  :user-id="user._id!"
                  :managing-emotion-score="user.assessments.managing_emotion!"
                  :understanding-emotion-score="user.assessments.understanding_emotion!"
                  :perceiving-emotion-score="user.assessments.perceiving_emotion!"
                  :dialog-open="showDetailedReports.perceiving_emotion"
                />
              </assessment-results-summary-card>
            </v-col>
            <v-col cols="12" md="4" v-if="canShowReportCard(n,Feature.EntrepreneurReport)">
              <assessment-results-summary-card
                :complete="reportAssessmentsComplete[Feature.EntrepreneurReport]"
                :title="$t(`assessments.entrepreneurial_intent.title`)"
                append-icon="fas fa-person"
                @view-report="handleViewReport('entrepreneurial')"
                data-pw="entrepReportCard"
              >
                <entrep-report
                  v-if="reportAssessmentsComplete[Feature.EntrepreneurReport]"
                  :user-id="user._id!"
                  :score="user.assessments.holland_code?.entrepreneurship!"
                  :dialog-open="showDetailedReports.entrepreneurial"
                  data-pw="entrepReportCardMetricData"
                />
              </assessment-results-summary-card>
            </v-col>
            <v-col cols="12" md="4" v-if="canShowReportCard(n,Feature.ValueScalesReport)">
              <assessment-results-summary-card
                :complete="reportAssessmentsComplete[Feature.ValueScalesReport]"
                :title="$t(`assessments.value_scales.title`)"
                append-icon="fas fa-school"
                @view-report="handleViewReport('value_scales')"
              >
                <value-report
                  v-if="reportAssessmentsComplete[Feature.ValueScalesReport]"
                  :user-id="user._id!"
                  :dialog-open="showDetailedReports.value_scales"
                />
              </assessment-results-summary-card>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <div class="d-flex justify-center align-center mt-4">
        <v-btn color="primary" icon="fas fa-chevron-left" @click="prevSlide" variant="text"/>
        <div class="mx-4">
          <v-btn
            v-for="n in carouselReports.length"
            :key="n"
            :color="currentCarouselItem === n - 1 ? 'primary' : 'grey'"
            size="small"
            @click="currentCarouselItem = n - 1"
            variant="plain"
          >
            <v-icon>fas fa-circle</v-icon>
          </v-btn>
        </div>
        <v-btn color="primary" icon="fas fa-chevron-right" @click="nextSlide" variant="text" data-pw="carousel-next-btn"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {ref, computed} from "vue";
import {PropType} from "vue";
import {AllowedFeature, DashUser, Feature, AccountType, ReportAssessmentsType} from "@YenzaCT/sdk";
import HollandCodeReport
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/HollandCodeReport/HollandCodeReport.vue";
import Big5Report from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/Big5Report/Big5Report.vue";
import LearningStylesReport
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/LearningStyleReport/LearningStylesReport.vue";
import CognitiveAbilityReport
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/CognitiveAbilityReport/CognitiveAbilityReport.vue";
import EmotionalIntelligenceReport
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/EmotionalIntelligenceReport/EmotionalIntelligenceReport.vue";
import AssessmentResultsSummaryCard
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/AssessmentResultsSummaryCard.vue";
import _ from "lodash";
import EntrepReport
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/EntrepreneurialIntentReport/EntrepReport.vue";
import ValueReport
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/ValueScalesReport/ValueScalesReport.vue";

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  },
  accountType: {
    type: String as PropType<AccountType>,
    required: true
  }
});

const availableReportCards = [
  Feature.WorkerTypeReport,
  Feature.PersonalityTraitsReport,
  Feature.LearningStylesReport,
  Feature.CognitiveAbilityReport,
  Feature.EmotionalIntelligenceReport,
  Feature.EntrepreneurReport,
  Feature.ValueScalesReport
];

const emit = defineEmits<{
  (e: "show-report", report: string): void;
}>();

interface DetailedReports {
  holland_code: boolean;
  big_5: boolean;
  learning_styles: boolean;
  perceiving_emotion: boolean;
  cognitive_ability: boolean;
  entrepreneurial: boolean;
  value_scales:boolean
}

const currentCarouselItem = ref(0);
const showDetailedReports = ref<DetailedReports>({
  holland_code: false,
  big_5: false,
  learning_styles: false,
  perceiving_emotion: false,
  cognitive_ability: false,
  entrepreneurial: false,
  value_scales: false,
});

const reports = availableReportCards.filter((report) => featureAllowed(report as Feature));
const reportAssessmentsComplete = computed<Record<Feature, boolean>>(() => {
  const reports: Record<Feature, boolean> = {} as Record<Feature, boolean>;
  for (const [report, assessments] of Object.entries(ReportAssessmentsType)) {
    reports[report as Feature] = assessments.reduce((acc, assessment) =>
      acc && !!props.user.assessments[assessment], true);
  }
  return reports;
});

const carouselReports = computed(() => _.chunk(reports, 3));

function featureAllowed(feature: Feature) {
  return AllowedFeature[props.user?.country][props.user.app.accountType].includes(feature);
}

function canShowReportCard(n: number, report: Feature) {
  return carouselReports.value[n - 1].includes(report);
}

function handleViewReport(report: keyof DetailedReports) {
  showDetailedReports.value[report] = true;
  emit("show-report", report);
}

const nextSlide = () => {
  currentCarouselItem.value =
    currentCarouselItem.value < carouselReports.value.length - 1
      ? currentCarouselItem.value + 1 : currentCarouselItem.value = 0;
};

const prevSlide = () => {
  currentCarouselItem.value =
    currentCarouselItem.value === 0
      ? currentCarouselItem.value = carouselReports.value.length - 1 : currentCarouselItem.value - 1;
};

</script>

<style scoped>
</style>
